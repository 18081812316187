.paiement .input-wrapper{
  width: calc(100% - 20px);
}

.input-wrapper {

  margin-left: 10px;
  margin-right: 10px;

  .input-title {
    color: #fff;
    margin-bottom: 13px;
  }

  .input {

    border: 1px solid #fff;
    border-radius: 6px;

    padding-left: 12px;
    padding-right: 12px;
    width: 295px;
    height: 40px;
    background: transparent;
    margin-bottom: 15px;
    color: #fff;

    &.small {
      width: 100px;
    }

    &.disabled {
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 750px) {

    margin-left: 0px;
    margin-right: 0px;
  }
}