/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v14-latin/montserrat-v14-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./fonts/montserrat-v14-latin/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./fonts/montserrat-v14-latin/montserrat-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('./fonts/montserrat-v14-latin/montserrat-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('./fonts/montserrat-v14-latin/montserrat-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('./fonts/montserrat-v14-latin/montserrat-v14-latin-regular.svg#Montserrat') format('svg');
  /* Legacy iOS */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenirnext';
  src: url('./fonts/AvenirNext-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

  /* Raleway-Medium */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Raleway-Medium.ttf');
    /* IE9 Compat Modes */
  }

  /* Raleway-Bold */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/Raleway-Bold.ttf');
    /* IE9 Compat Modes */
  }

  /* Raleway-ExtraBold */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/Raleway-ExtraBold.ttf');
    /* IE9 Compat Modes */
  }

  /* Roboto-Regular */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Roboto-Regular.ttf');
    /* IE9 Compat Modes */
  }

  /* Roboto-Medium */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Roboto-Medium.ttf');
    /* IE9 Compat Modes */
  }

  /* Roboto-Light */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/Roboto-Light.ttf');
    /* IE9 Compat Modes */
  }

  /* Roboto-Bold */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/Roboto-Bold.ttf');
    /* IE9 Compat Modes */
  }

  /* OpenSans-Bold */
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/OpenSans-Bold.ttf');
    /* IE9 Compat Modes */
  }

  /* OpenSans-ExtraBold */
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/OpenSans-ExtraBold.ttf');
    /* IE9 Compat Modes */
  }

  /* OpenSans-SemiBold */
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/OpenSans-SemiBold.ttf');
    /* IE9 Compat Modes */
  }

  /* OpenSans-Bold */
  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/OpenSans-Regular.ttf');
    /* IE9 Compat Modes */
  }

  /* BigNoodleTitling */
  @font-face {
    font-family: 'BigNoodleTitling';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/big_noodle_titling.ttf');
    /* IE9 Compat Modes */
  }


  body {
    font-family: "AvenirNext-DemiBold", "Montserrat", 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  .page-title {
    font-family: "AvenirNext-HeavyItalic", "Montserrat", 'Helvetica Neue', sans-serif;
  }