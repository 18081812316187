@import "../../../theme/colors";

.responsive-logo {
  display: none;
}

.hamburger {
  position: fixed;
  top     : 22px;
  left    : 86%;
  display : none;

  font-size: 26px;
  color    : #fff;

  justify-content: center;
  align-items    : center;
  cursor         : pointer;

  z-index: 999; // important
}

.fa-list {
  content            : "";
  background         : url("../../../images/burger.png") no-repeat;
  background-size    : contain;
  background-position: left top;
  width              : 40px;
  height             : 40px;
}

.fa-list::before {
  content: "";
}

.main-header-bar.font {
  font-family            : "AvenirNext-DemiBold", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background: linear-gradient(214.28deg, #26AB81 -36.35%, #3D175F 90.73%);
}

.main-header-bar {
  z-index   : 1000; // important
  position  : fixed;
  top       : 0;
  right     : 0;
  background: transparent;
  left      : 80px;
  // right: 86px;
  -webkit-transition: left 2s, width 2s;

  .menu {
    flex           : 1;
    display        : flex;
    flex-direction : row;
    // align-items: center;
    height         : 100%;
    justify-content: space-between;

    .main-logo {
      width       : 80px;
      margin-right: 62px;
      object-fit  : contain;
      margin      : 20px;
    }

    .logo-link {
      margin-right: 3%;
    }

    .link {
      margin        : 20px;
      color         : #fff;
      text-align    : center;
      text-transform: uppercase;
      font-size     : 14.4px;
      font-weight   : 600;
      line-height   : 19px;
      margin-top    : 48px;

      a {
        color          : #fff;
        text-decoration: none;

        &:hover {
          cursor: pointer;
          color : $teal !important;
        }
      }

      &:hover {
        cursor: pointer;
        color : $teal !important;
      }
    }

    .login-btns {
      padding-right : 15px;
      flex-direction: column;

      .link-btn {
        margin-top: 10px;
      }

      .flags {
        position: relative;

      }

    }
  }

  .close {
    position: fixed;
    top     : 22px;
    right   : 22px;
    display : none;

    font-size: 26px;
    color    : #fff;

    justify-content: center;
    align-items    : center;
    cursor         : pointer;
  }

  &.shown {
    bottom       : 0;
    // background: linear-gradient(128.28deg, #26ab81 -36.35%, #3d175f 90.73%);
    background   : $blueGradientTransparent;
    display      : block;
    // transition: all 0.3s;
    visibility   : visible;
    width: 80%;
    // left: 0px;
    .menu {
      flex-direction: column;
      padding-top   : 15px;
      padding-bottom: 30px;
      transition    : all 1s;

      .main-logo {
        width: 80px;
      }

      .link {
        font-weight: bold;
        font-size  : 3.4vh;
      }
    }

    .hamburger {
      display: flex;
    }

    .close {
      display: flex;
    }
  }

  transition: all 0.6s;

}

.main-header-bar.active {
  // background: linear-gradient(
  //   90deg,
  //   rgb(21, 26, 36),
  //   rgba(0, 0, 0, 0.9),
  //   rgb(21, 26, 36)
  // );
  background: $blueGradientTransparent;
  transition: all 0.3s;

  left         : 0;
  right        : 0;
  padding-left : 86px;
  padding-right: 86px;
  // background: linear-gradient(214.28deg, #26AB81 -36.35%, #3D175F 90.73%);
}

@media screen and (max-width: 750px) {
  .flags {
    right   : 5px;
    bottom  : 10px;
    position: absolute !important;
  }

  .login-btns {
    margin-bottom: 25px;
  }

  .logo-link {
    display: none;
  }

  .responsive-logo {
    display   : block;
    position  : fixed;
    width     : 60px;
    top       : 14px;
    left      : 14px;
    z-index   : 999;
    // display: none;
  }

  html {
    width     : 100vw;
    overflow-x: hidden;
  }

  .main-header-bar {
    width: 0%;
    // display: none;
    visibility   : hidden;
    // transition: visibility 0s, opacity 0.5s linear;
    // transition: all ease 0.3s;

  }

  .hamburger {
    display: flex;
  }

  .header-overlay {
    background-color: #00000035;
    border-radius   : 5px;
    height          : 75px;
    width           : 97%;
    display         : flex;
    position        : fixed;
    top             : 6px;
    left            : 7px;
    z-index         : 11;
  }
}

@media screen and (min-width: 1000px) {
  .login-btns {
    flex-direction : row !important;
    justify-content: flex-end;
    flex           : 1;

    html {
      width     : 100vw;
      overflow-x: hidden;
    }

    .link-btn {
      margin-top  : 0 !important;
      margin-right: 29px;
    }
  }
}