.login-screen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .top-padding {
    padding-top: 5vh !important;
  }
  .logo {
    height: unset;
  }
}

@media screen and (min-width: 750px) {
  .noWrap {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 750px) {
  .info-login-form {
    padding: 2rem;
  }
}

.login-outbox {
  background: linear-gradient(to bottom, #68ead1 100%, #67c3e6 51%, #68bdf1 0%);
  border-radius: 15px;
  box-shadow: 0 0 18px 0 rgba(138, 225, 255, 0.25);
  position: relative;
  padding: 3px;
}

.info-login-form {
  background-color: rgb(9, 28, 57);
  margin: 0;
  border-radius: 15px;
  border: none;
  max-width: 90vw;

  .page-title {
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 2rem;
  }
}

.dark-input {
  border: none !important;
  background: #13284f;
  background-image: none !important;
  background-color: #13284f !important;
  color: inherit !important;
}

.dark-input:-internal-autofill-selected {
  border: none !important;
  background-image: none !important;
  background-color: #13284f !important;
  color: inherit !important;
}

.password_container_login {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & > .input-wrapper > input {
    margin-bottom: 0px !important;
  }
}

.login-page-link {
  font-size: 14px;
}

.bar-divider {
  width: 100%;
  height: 1px;
  background: rgba(53, 97, 124, 0.5);
  margin-top: 1rem;
}

.login-btn {
  width: 100%;
  padding: 0.75rem 0;
  font-size: 1rem;
  min-height: 0;
  height: auto !important;
  background: #faf56a !important;
  color: #3c4045;
  transition: background 0.3s ease-in-out;
}

.login-btn:hover {
  background: #fdf74d !important;
}

.login-logo-container {
  display: flex;
  justify-self: center;
  margin-bottom: 2rem;
  cursor: pointer;
}
