.loader {
  width: 200px;
  height: 200px;
  border: 4px solid #fffa7e;
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  position: relative;
  position: relative;
}

.loader_background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  background: linear-gradient(190deg, #0b1846, #173f5e);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader_no-background {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  // background : linear-gradient(190deg, #0b1846, #173f5e);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_loading {
  position: absolute;
  width: 150px;
  height: 110px;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 750px) {
  .loader {
    width: 120px;
    height: 120px;
    border: 2px solid #fffa7e;
    border-right-color: transparent;
  }
  .logo_loading {
    width: 80px;
    height: 50px;
  }
}
